import React from "react";
import useAuth from "../hooks/useAuth";
import { navigate } from "gatsby";
import { useEffect } from "react";
import Layout from "../components/layouts/Layout";
import Header from "../components/Header";
import SEO from "../components/SEO";

function Login() {
  const { login, tokenRenewalComplete, getAuthenticationState } = useAuth();
  const isAuthenticated = getAuthenticationState();

  useEffect(() => {
    if (!tokenRenewalComplete) return;

    if (isAuthenticated) {
      navigate("/home/");
      return;
    }

    login("/");
    return;
  }, [tokenRenewalComplete]);

  return (
    <Layout>
      <SEO path="/login/" noIndex />
      <Header title="Logging in..." />
    </Layout>
  );
}

export default Login;
