import React from "react";
import HSeparator from "../HSeparator";
import NavRoutes from "../NavRoutes";

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props) {
  return (
    <React.Fragment>
      <NavRoutes />
      <HSeparator />
      <main
        id="main-content"
        role="main"
        style={{ margin: "0 auto", maxWidth: "900px", padding: "0 8px" }}
      >
        {children}
      </main>
    </React.Fragment>
  );
}

export default Layout;
